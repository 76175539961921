.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
  
.dialog {
    position: fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: darkslategray;
    border: solid;
    border-width: 2px;
    border-color: slategray;
    padding: 20px;
}

.close-image {
    filter: invert(1);
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -12px;
}
