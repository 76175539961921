.month-row {
    display: flex;
}

.month-header {
    min-width: 50px;
    font-size: x-large;
    transform: translateY(45%);
}

.object-row {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: groove;
    border-width: 1px;
}

.object-strecher {
    flex: 1;
    border-bottom: groove;
    border-width: 1px;
}
