.tab-selector-row {
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
}

.tab-button {
    margin-left: 15px;
    margin-right: 15px;
    height: 30px;
    font-size: large;
    background-color: darkslategray;
    color: white;
}

.tab-button-selected {
    margin-left: 15px;
    margin-right: 15px;
    height: 30px;
    font-size: large;
}
