.header-box {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    height: 100px;
    left: 40px;
    right: 0px;
    overflow: hidden;
}

.header-title {
    flex-basis: 33%;
}

.header-tabs {
    flex-basis: 33%;
    min-width: 500px;
}

.header-buttons {
    flex-basis: 33%;
    display: flex;
    justify-content: right;
}

.content-box {
    position: absolute;
    top: 100px;
    bottom: 25px;
    left: 40px;
    right: 0px;
    overflow: auto;
}

.footer-box {
    position: absolute;
    bottom: 0px;
    height: 25px;
    left: 0px;
    right: 0px;
    overflow: hidden;
}