.no-data {
    margin-top: 40px;
    font-size: large;
}

.stat-table {
    margin: auto;
    margin-top: 40px;
    font-size: large;
    width: 75%;
}

th {
    border-bottom: solid 2px;
}

.name-header {
    text-align: left;
}

.name-field {
    text-align: left;
    width: 250px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.name-field:hover {
    white-space: wrap;
    font-weight: bold;
    color: #04AA6D;
}

.number-field {
    text-align: right;
    width: 50px;
}
