.title-link {
    color: inherit;
    text-decoration: inherit;
}

.main-title {
    margin-bottom: 0px;
}

.sub-title {
    margin-top: 0px;
    font-style: italic;
}
