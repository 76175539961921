.object-box-small {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 200px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.object-box-large {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 400px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.object-box-xlarge {
    margin: 20px;
}

.selection-box {
    border: solid;
    border-width: 3px;
    border-color: orange;
    padding: 2px;
}

.highlight-box {
    border: dashed;
    border-width: 3px;
    border-color: lightgray;
    padding: 2px;
}

.invisible-box {
    border: solid;
    border-width: 3px;
    border-color: transparent;
    padding: 2px;
}

.image-box {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0px;
    margin-right: 0px;
}

.image {
    max-width: 100%;
    max-height: 65vh;
}

.title-small {
    font-size: medium;
    max-width: 175px;
    margin-left: auto;
    margin-right: auto;
}

.title-large {
    font-size: large;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.title-xlarge {
    font-size: large;
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
}

.subtitle-small {
    font-size: small;
    max-width: 175px;
    margin-left: auto;
    margin-right: auto;
}

.subtitle-large {
    font-size: medium;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.subtitle-xlarge {
    font-size: medium;
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
}
