.home-box {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.group-box {
    margin-top: 80px;
    text-align: left;
    font-size: x-large;
}

.tab-box {
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
    font-size: large;
}

.tab-link-box {
    padding: 3px;
    min-width: 150px;
    text-align: center;
    background-color: darkslategray;
    border: solid 1px;
    max-height: 20px;
}

.tab-link {
    appearance: button;
    text-decoration: none;
    color: white;
    width: 100%;
}

.tab-text {
    margin-left: 20px;
    padding-top: 3px;
}