.date-slider {
    display: flex;
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.date-text {
    font-size: x-large;
    text-align: left;
    min-width: 130px;
}

.slider {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    margin-right: 20px;
    margin-top: 5px;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 30px;
    border-radius: 2px;
    background: #04AA6D;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 10px;
    height: 30px;
    border-radius: 2px;
    background: #04AA6D;
    cursor: pointer;
}
   