.month-block-header {
    margin-top: 20px;
    min-width: 50px;
    font-size: x-large;
    border-bottom: groove 1px;
}

.issue-block {
    display: flex;
    justify-content: center;
}

.story-block {
    display: flex;
}

.story-comment {
    margin-top: auto;
    margin-bottom: auto;
    width: 300px;
}
